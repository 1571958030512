<!--
 * @Description: 财务管理系统 财务管理 支付记录 accPayRecord
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-03 14:21:59
 * @LastEditors: zhoucheng
-->
<template>
  <div class='accPayRecord-mainbody'>
    <el-table :data="tableData"
              style="width: 100%">
      <el-table-column label="街道/时间"
                       prop="time">
      </el-table-column>
      <el-table-column :label="cla">
        <template slot-scope="scope">
          <el-row>{{scope.row.code}}</el-row>
          <el-row>{{scope.row.name}}</el-row>
          <el-row>{{scope.row.date}}</el-row>
        </template>
      </el-table-column>
      <el-table-column label="Name"
                       prop="name">
      </el-table-column>
      <el-table-column label="Date"
                       prop="date">
      </el-table-column>
      <el-table-column label="Name"
                       prop="name">
      </el-table-column>
      <el-table-column label="Date"
                       prop="date">
      </el-table-column>
      <el-table-column label="Name"
                       prop="name">
      </el-table-column>
      <el-table-column label="Name"
                       prop="name">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      cla: "aaaaaa",
      tableData: [{
        time: "龙塔街道",
        code: "1",
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        time: "宝山街道",
        code: "2",
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        time: "龙湖街道",
        code: "3",
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        time: "宝圣湖街道",
        code: "4",
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }],
      search: ''
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    handleEdit (index, row) {
      console.log(index, row);
    },
    handleDelete (index, row) {
      console.log(index, row);
    }
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.accPayRecord-mainbody {
  width: 100%;
  height: 100%;
}
</style>